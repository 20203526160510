import React from 'react'
import Layout from '../components/shared/Layout'
import SEO from '../components/seo'

const Logos = () => {
    return (<Layout>
        <SEO title="Logoer"/>
                
                <div className="wrap" role="document">
                    <div className="content">

                        <main className="main" id="main">
                            <header className="header">
                                <div className="container">
                                    <div className="header__caption">
                                        <h1>Logoer</h1>
                                    </div>
                                </div>
                            </header>

                            <div className="container">
                                <div className="row">
                                    <div className="col-md-3 ">
                                        <div className="submenu">
                                            <ul className="submenu__list">
                                                <li className="submenu__item"><a href="#logo-primary">Hovedlogo kjede</a></li>
                                                <li className="submenu__item"><a href="#logo-bw">Sort og negativ logo</a></li>
                                                {/* <li className="submenu__item"><a href="#logo-name">Juridiske logoer</a></li> */}
                                                <li className="submenu__item"><a href="#dealer-name">Kontor logoer</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <p className="lead ingress">
                                            Bokstaven S setter et stødig anslag. 
                                            Symbolspråket hinter om matematikk, trapp, sten på sten, sam­handling og næringsliv. 
                                            Fonten er jovial og kontrasterer elegant det stiliserte ­symbolet.
                                            Opplevelsen er trygg, moderne og monumental.
                                            </p>
                                            <p className="lead">
                                                Det er utviklet tre ulike logo-nivåer:
                                            </p>
                                            <p className="lead">
                                                1. Saga KL kjedelogo: Dette er en logo som benyttes overordnet på felles 
                                                kontaktflater, kampanjer og profilelementer. Hvert selskap har anledning til 
                                                å benytte denne logoen der de måtte ønske. Det kan blant annet være hensiktsmessig 
                                                å velge denne på områder der lesbarhet er viktig, som f.eks. store flater som skilt 
                                                og stadionreklame eller svært små flater som kulepenner, pins e.l.
                                            </p>
                                            <p className="lead">
                                                2. Logo med juridiske navn: Hvert selskap får sin egen logo, med sitt juridiske navn. 
                                                Denne benyttes på lokale kontaktflater som f.eks visittkort, brevark og konvolutter.
                                            </p>
                                            <p className="lead">
                                                3. Tilleggslogo/navnetrekk med juridisk navn: Hvert selskap får sitt navnetrekk laget 
                                                som en tilleggslogo. Dersom man har behov for å bruke det juridiske navnet alene, 
                                                benyttes denne filen for å sikre helhet i det visuelle uttrykket. Navnetrekket 
                                                kan også benyttes sammen med kjedelogoen, men da må det være en god avstand mellom de to.
                                            </p>
                                        <hr/>

                                        <div id="logo-primary" className="section">
                                            <h2>Hovedlogo kjede</h2>
                                            <a href="https://sagaservices.blob.core.windows.net/downloads/logo/horisontal-logo-saga-kl.zip" className="btn button button--download mx-auto"><i className="fa fa-arrow-down icon--underline"></i> Last ned hovedlogo</a>
                                            <img className="img-fluid" src="../img/sections/logo/logo1-new.jpg" alt="Saga KL Profilmanual HOVEDLOGO 900x600"/>
                                            <div className="container-fluid no-padding">
                                                {/* <div className="row no-gutters spacing-top">
                                                    <div className="col-md-5">
                                                        <h3>Hovedlogo kjede vertikal</h3>
                                                        <a href="https://sagaservices.blob.core.windows.net/downloads/logo/vertikal-logo-saga-kl.zip" className="btn button button--download mx-auto"><i className="fa fa-arrow-down icon--underline"></i> Last ned vertikal logo</a>
                                                    </div>
                                                    <div className="col-md-7">
                                                        <img className="img-fluid" src="../img/sections/logo/logo2-new.jpg" alt="Saga KL Profilmanual VERTIKAL LOGO 900x600"/>
                                                    </div>
                                                </div> */}
                                                <div className="row no-gutters spacing-top">
                                                    <div className="col-md-5">
                                                        <p>Logoen skal alltid ha luft rundt seg. Luften er er ­definert som tykkelsen på øverste stolpe i logo­symbolet “S”.</p>
                                                    </div>
                                                    <div className="col-md-7">
                                                        <img className="img-fluid" src="../img/sections/logo/logo6-new.jpg" alt="Saga KL Profilmanual HOVEDLOGO 900x600"/>
                                                    </div>
                                                </div>
                                                <div className="row no-gutters spacing-top">
                                                    <div className="col-md-5">
                                                        <p>Logoen må fortrinnsvis stå på hvit bunn. Men kan også stå på bunn / bilder med svake, duse valører. Det må alltid sikres god kontrast til logo.</p>
                                                    </div>
                                                    <div className="col-md-7">
                                                        <img className="img-fluid" src="../img/sections/logo/logo8-new.jpg" alt="Saga KL Profilmanual HOVEDLOGO 900x600"/>
                                                    </div>
                                                </div>
                                                <div className="row no-gutters spacing-top">
                                                    <div className="col-md-5">
                                                        <p>Ikke tillatte logoversjoner</p>
                                                    </div>
                                                    <div className="col-md-7">
                                                        <img className="img-fluid" src="../img/sections/logo/logo7-new.jpg" alt="Saga KL Profilmanual IKKE TILLATTE LOGOER 900x600"/>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <hr/>

                                        <div id="logo-bw" className="section">
                                            <h2>Sort og negativ logoversjoner</h2>
                                            <p>I disse versjonene står “services” i 40 % sort.</p>
                                            <img className="img-fluid" src="../img/sections/logo/logo4-new.jpg" alt="Saga KL Profilmanual SORT LOGO 900x600"/>
                                            <img className="img-fluid" src="../img/sections/logo/logo5-new.jpg" alt="DP Profilmanual NEGATIV LOGO 900x600"/>
                                        </div>

                                        <hr/>

                                        {/* <div id="logo-name" className="section">
                                            <div className="container-fluid no-padding">
                                                <h2>Juridiske logoer</h2>
                                                <div className="row no-gutters spacing-top">
                                                    <div className="col-md-5">
                                                        <div className="justify-content-center align-self-center">
                                                            <p>Juridiske logoer</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7">
                                                        <img className="img-fluid" src="../img/sections/logo/logo3.jpg" alt="Saga KL Profilmanual JURIDISK LOGO 900x600"/>
                                                    </div>
                                                </div>
                                                <div className="row no-gutters spacing-top">
                                                    <div className="col-md-5">
                                                        <p>Navnetrekk juridiske logoer</p>
                                                    </div>
                                                    <div className="col-md-7">
                                                        <img className="img-fluid" src="../img/sections/logo/logo9.jpg" alt="Saga KL Profilmanual NAVNETREKK JURIDISK LOGO 900x600"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}






                                        <div id="dealer-name" className="section">
                                            <h2>Kontorer</h2>
                                            <p>Last ned juridisk logopakke ved å klikke på ditt kontor.</p>
                                            <div className="container-fluid no-padding">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <ul className="dealer__list">
                                                            <li className="dealer__item"><a href="https://sagaservices.blob.core.windows.net/downloads/logo/kontor/abc-accounting.zip">ABC Accounting</a></li>
                                                            {/* <li className="dealer__item"><a href="https://sagaservices.blob.core.windows.net/downloads/logo/kontor/dala-okonomi.zip">Dala Økonomi</a></li> */}
                                                            <li className="dealer__item"><a href="https://sagaservices.blob.core.windows.net/downloads/logo/kontor/drammen-data.zip">Drammen Data</a></li>
                                                            <li className="dealer__item"><a href="https://sagaservices.blob.core.windows.net/downloads/logo/kontor/kl-creditreform-okonomi.zip">KL Creditreform Økonomi</a></li>
                                                            <li className="dealer__item"><a href="https://sagaservices.blob.core.windows.net/downloads/logo/kontor/kl-okonomi-og-hr.zip">KL Økonomi og HR</a></li>
                                                            <li className="dealer__item"><a href="https://sagaservices.blob.core.windows.net/downloads/logo/kontor/kl-regnskap-hitra.zip">KL Regnskap Hitra</a></li>
                                                            <li className="dealer__item"><a href="https://sagaservices.blob.core.windows.net/downloads/logo/kontor/kl-regnskap-selbu.zip">KL Regnskap Selbu</a></li>
                                                            <li className="dealer__item"><a href="https://sagaservices.blob.core.windows.net/downloads/logo/kontor/ma-regnskap.zip">MA Regnskap</a></li>
                                                            <li className="dealer__item"><a href="https://sagaservices.blob.core.windows.net/downloads/logo/kontor/ocon-regnskap.zip">OCON Regnskap</a></li>
                                                            <li className="dealer__item"><a href="https://sagaservices.blob.core.windows.net/downloads/logo/kontor/orkas.zip">Orkas</a></li>
                                                            <li className="dealer__item"><a href="https://sagaservices.blob.core.windows.net/downloads/logo/kontor/regnskapssentralen.zip">Regnskapssentralen</a></li>
                                                            <li className="dealer__item"><a href="https://sagaservices.blob.core.windows.net/downloads/logo/kontor/regnskapstjenester-eidsvoll.zip">Regnskapstjenester Eidsvoll</a></li>
                                                            <li className="dealer__item"><a href="https://sagaservices.blob.core.windows.net/downloads/logo/kontor/saga-entreprenor-regnskap.zip">Saga Entreprenør Regnskap</a></li>
                                                            <li className="dealer__item"><a href="https://sagaservices.blob.core.windows.net/downloads/logo/kontor/saga-regnskap-asker-og-barum.zip">Saga Regnskap Asker og Bærum</a></li>
                                                            <li className="dealer__item"><a href="https://sagaservices.blob.core.windows.net/downloads/logo/kontor/saga-regnskap-honefoss.zip">Saga Regnskap Hønefoss</a></li>
                                                            <li className="dealer__item"><a href="https://sagaservices.blob.core.windows.net/downloads/logo/kontor/saga-regnskap-og-okonomi.zip">Saga Regnskap og Økonomi</a></li>
                                                            <li className="dealer__item"><a href="https://sagaservices.blob.core.windows.net/downloads/logo/kontor/saga-regnskap-og-radgivning.zip">Saga Regnskap og Rådgivning</a></li>
                                                            <li className="dealer__item"><a href="https://sagaservices.blob.core.windows.net/downloads/logo/kontor/saga-regnskap-tonsberg.zip">Saga Regnskap Tønsberg</a></li>
                                                            <li className="dealer__item"><a href="https://sagaservices.blob.core.windows.net/downloads/logo/kontor/sorum-okonomi.zip">Sørum Økonomi</a></li>
                                                            <li className="dealer__item"><a href="https://sagaservices.blob.core.windows.net/downloads/logo/kontor/Vestby-Regnskapskontor-logoer.zip">Vestby Regnskapskontor</a></li>
                                                        </ul>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                        </main>

                    </div>
                </div>
    
    </Layout>)
}

export default Logos